<template>
<div>
  <van-form>
    <van-field
        v-model="form.oldPassword"
        type="password"
        :label="$t('旧密码')"
        :placeholder="$t('请输入旧密码未设置密码可留空')"

    />
    <van-field
        v-model="form.password"
        type="password"
        :label="$t('新密码')"
        :placeholder="$t('数字字母或字符')"
        :rules="[{ required: true, message: $t('请填写新密码') }]"
    />
    <van-field
        v-model="form.confirmPassword"
        type="password"
        :label="$t('确认密码')"
        :placeholder="$t('请再次输入密码')"
        :rules="[{ required: true, message: $t('请填写确认密码') }]"
    />
    <div style="margin: 16px;">
      <van-button :loading="loading" round block type="info" @click="onSubmit" native-type="submit">{{$t('提交')}}</van-button>
    </div>
  </van-form>
</div>
</template>

<script>
import {Form, Field,Button,Notify} from "vant";
import {modifyPwd} from "@/api/user";

export default {
  name: "index",
  components:{
    [Form.name]:Form,
    [Field.name]:Field,
    [Button.name]:Button,
  },
  data(){
    return ({
      form:{},
      loading:false
    })
  },
  methods:{
    onSubmit(){
      let form = this.form;
      if(form.password && form.confirmPassword ){
        if(form.password.length < 6 || form.password.length > 12){
          Notify({ type: 'danger', message: this.$t('密码应是数字字母或字符' )});
          return;
        }
        if(form.password != form.confirmPassword){
          // 危险通知
          Notify({ type: 'danger', message: this.$t('两次输入的密码不一致请检查重试') });
          return;
        }
        this.loading= true;
        modifyPwd({oldPassword:form.oldPassword,newPassword:form.password})
        .then(() => {
          Notify({ type: 'success', message: this.$t('密码修改成功') });
        }).finally(() => {
          this.loading= false;
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
